<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div id="body-content-area" style="overflow: hidden;">
         <div v-if="isLoading">
          <Loading />
        </div>
        <div v-else>
          <div id="pagelayout">
            <div v-if="errors && errors.length">
              <div v-for="error in errrors" :key="error">{{error}}</div>
            </div>

            <div v-if="institutions && institutions.length > 0">
              <div class="grid-container">
                <div class="bottom-bar">
                  <div class="buttons">
                    <button :disabled="!$hasPermissions(clientSession, ['ANNOUNCEMENTS'], 2)" @click="$router.push({ name: 'AnnouncementDetailsNew', model: null})" class="button is-accent"><i class="fa fa-plus" style="margin-right: .75rem; margin-left: 0"></i>Add Announcement</button>
                    <button :disabled="selectedRows.length < 1" @click="!$hasPermissions(clientSession, ['ANNOUNCEMENTS'], 2) ? null : showDeleteModal = true" :class="['button is-dark ', selectedRows.length < 1 ? 'disabled' : '', isDeleting ? 'is-loading' : '']"><i class="fa fa-trash" style="margin-right: .75rem; margin-left: 0"></i>Delete Announcement{{selectedRows.length > 1 ? 's' : ''}}</button>
                  </div>
                </div>
                <Grid
                  v-if="maxHeight > 0"
                  @headerselectionchange="onHeaderSelectionChange"
                  @selectionchange="onSelectionChange"
                  :style="{maxheight: maxHeight+'px', height: maxHeight+'px'}"
                  :data-items="announcementsList"
                  :columns="columns"
                  :selected-field="selectedField"
                  :sortable="true"
                  :take="pagination.take"
                  :skip="pagination.skip"
                  :pageable="{
                    ...pageable,
                    pageSizes: pageSizes
                  }"
                  :page-size="minResults"
                  :total="totalRecords"
                  :sort="sort"
                  @pagechange="pageChangeHandler"
                  @sortchange="sortchange"
                >
                  <template v-slot:nameCell="{props}">
                    <td>
                      <router-link class="has-text-accent" :to="{ name: 'AnnouncementDetails', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}` }}">
                        {{props.dataItem.name}}
                      </router-link>
                    </td>
                  </template>

                  <template v-slot:institution="{props}">
                    <td>
                      <div v-html="getInstitutionCells(props.dataItem.institutionIds)"></div>
                    </td>
                  </template>

                  <template v-slot:startDate="{props}">
                    <td>
                      {{formatDateInLocalTimezone(props.dataItem.startDate, 'YYYY-MM-DD hh:mm A')}}
                    </td>
                  </template>

                  <template v-slot:expireDate="{props}">
                    <td>
                      {{formatDateInLocalTimezone(props.dataItem.expirationDate, 'YYYY-MM-DD hh:mm A')}}
                    </td>
                  </template>

                  <template v-slot:isActive="{props}">
                    <td>
                      {{ getIsActive(props.dataItem) }}
                    </td>
                  </template>
                </Grid>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                  <div class="content">
                  Type "<b>delete</b>" to confirm that you want to delete {{selectedRows.length > 1 ? 'these' : 'this'}} announcement{{selectedRows.length > 1 ? 's' : ''}}.
                  </div>
                  <div class="field">
                  <div class="control">
                      <input
                      type="text"
                      class="input"
                      placeholder="type `delete` and then click confirm"
                      v-focus
                      v-model.trim="confirmationText"
                      @keydown.enter.prevent="deleteIfConfirmed"
                      />
                  </div>
                  </div>
              </section>
              <footer class="modal-card-foot">
                  <a
                  class="button is-danger"
                  @click="deleteIfConfirmed"
                  :disabled="confirmationText !== 'delete'"
                  >
                  <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
              </div>
          </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
// import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { Grid } from '@progress/kendo-vue-grid'
import { orderBy, filterBy } from '@progress/kendo-data-query'

let pageName = 'Announcement Management'
// Vue.use(GridInstaller)

export default {
  components: {
    Loading,
    'Grid': Grid
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      confirmationText: '',
      showDeleteModal: false,
      columns: [
        { selectable: true, field: 'selected', width: '30px' },
        { field: 'name', title: 'Name', cell: 'nameCell' },
        { field: 'message', title: 'Message', width: '500' },
        { field: 'institutionIds', title: 'Recipients', cell: 'institution' },
        { field: 'startDate', title: 'Start Date', cell: 'startDate' },
        { field: 'expirationDate', title: 'Expire Date', cell: 'expireDate' },
        { field: 'isActive', title: 'Status', cell: 'isActive' }
      ],
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      selectedRows: [],
      filter: {
        logic: 'and',
        filters: []
      },
      pagination: {
        skip: 0,
        take: 5
      },
      totalRecords: 0,
      institutions: null,
      isLoading: true,
      selectedField: 'selected',
      announcements: [],
      isDeleting: false
    }
  },
  watch: {
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    pageheading () {
      return pageName
    },
    announcementsList () {
      if (this.announcements.length === 0) { return [] }

      let filtered = filterBy(this.announcements, this.filter)
      return orderBy(filtered, this.sort)
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  created () {
    this.getInstitutions()
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.pagination.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight - 50

        this.getAnnouncements()
        this.getInstitutions()
      }

      that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
      })
    })
  },
  methods: {
    async deleteIfConfirmed () {
      if (this.confirmationText.toLowerCase() === 'delete') {
        await this.deleteAnnouncements()
        this.showDeleteModal = false
        this.isLoading = false
      }
    },
    pageChangeHandler (event) {
      this.pagination = {
        skip: event.page.skip,
        take: event.page.take
      }

      this.getAnnouncements()
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }

      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'announcements', this.announcements.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.announcements
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    async getInstitutions () {
      let that = this
      this.api().getInstitutions((_response, _error) => {
        if (_error) {
          that.handleApiErr(_error)
          that.errors.push('Unable to load institutions')
        }

        that.institutions = _response.records
      })
    },
    getInstitutionCells (institutions) {
      let that = this
      let cell = ''
      let _institutions = institutions.split(', ')
      if (_institutions.length > 0 && _institutions[0] !== '') {
        _institutions.map(_institution => {
          let record = that.institutions.filter(__institution => {
            if (parseInt(__institution.id) === parseInt(_institution)) {
              return __institution
            }
          })

          if (record[0] && record[0].name !== undefined && record[0].name !== null && record[0].name !== '') {
            cell += `<div class="institutionName">${record[0].name}</div>`
          }
        })
      }

      if (cell === '') {
        return 'All'
      }

      return cell
    },
    formatEndDate (format) {
      let dateTime = moment().add(12, 'hours')
      return dateTime.format(format)
    },

    formatStartDate (format) {
      let dateTime = moment()
      return dateTime.format(format)
    },
    sortchange (e) {
      this.sort = e.sort
      this.getAnnouncements()
    },
    async deleteAnnouncements () {
      let that = this

      this.selectedRows.map(_announcement => {
        that.selectedRows = that.selectedRows.filter(_row => {
          if (_row.id !== _announcement.id) {
            return _row
          }
        })
        that.isDeleting = true
        that.api().deleteAnnouncement(_announcement, (_response, _error) => {
          if (_error) {
            that.errors.push(_error)
          }

          that.isDeleting = false
          that.getAnnouncements()
        })
      })
    },
    getIsActive (item) {
      return moment(item.expirationDate).isBefore(moment()) ? 'Expired' : 'Active'
    },
    async getAnnouncements () {
      let that = this
      this.isLoading = true
      this.api().getAnnouncements({
        offset: this.pagination.skip,
        limit: this.pagination.take,
        sort: this.sort[0] ? this.sort[0].field : 'name',
        sortDirection: this.sort[0] ? this.sort[0].dir : 'ASC'
      }, (_response, _error) => {
        if (_error) {
        }
        if (_response && _response.records && _response.records.length > 0) {
          that.announcements = _response.records
          that.totalRecords = _response.page.totalRecords
        } else {
          that.announcements = []
          that.totalRecords = 0
        }

        that.isLoading = false
      })
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.grid-container {
  margin-bottom: 1rem;
}

.visible {
  visibility: hidden;
}

.bottom-bar {
  margin-top: 0rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: flex-end;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}

.k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
